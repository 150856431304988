import { BehaviorSubject } from 'rxjs';

import { inject, Injectable } from '@angular/core';
import { Auth, updateEmail, updatePassword } from '@angular/fire/auth';
import { Functions, httpsCallable } from '@angular/fire/functions';
import {
  CollectionName,
  FunctionName,
} from '@verify/shared-components/helpers';
import { Tenant, User } from '@verify/shared-components/models';

import { FirestoreService } from './firestore.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private auth: Auth = inject(Auth);
  private functions = inject(Functions);
  private firestore: FirestoreService = inject(FirestoreService);

  private static defaultTenant = 'dev1';

  private readonly _currentUser: BehaviorSubject<User | undefined> =
    new BehaviorSubject<User | undefined>(undefined);
  readonly currentUser$ = this._currentUser.asObservable();

  private readonly _tenant: BehaviorSubject<Tenant | undefined> =
    new BehaviorSubject<Tenant | undefined>(undefined);
  readonly tenant$ = this._tenant.asObservable();

  constructor() {
    if (!this.auth.tenantId) {
      this.getTenantInfo();
    }
  }

  get currentUser(): User | undefined {
    return this._currentUser.value;
  }

  get tenant(): Tenant {
    return this._tenant.value;
  }

  get tenantId(): string {
    return this.auth?.tenantId;
  }

  getAuth(): Auth {
    return this.auth;
  }

  getTenantName(): string {
    const hostParts = window.location.host.split('.');
    return hostParts.length > 1
      ? hostParts[0].padEnd(4, '-')
      : AuthService.defaultTenant;
  }

  updateEmail(newEmail: string): Promise<void> {
    return updateEmail(this.auth.currentUser, newEmail);
  }

  updatePassword(newPassword: string): Promise<void> {
    return updatePassword(this.auth.currentUser, newPassword);
  }

  private findUser(userUid: string): void {
    this.firestore
      .getDocument<User>(`${CollectionName.users}/${userUid}`)
      .subscribe((user) => {
        this._currentUser.next(user || undefined);
      });
  }

  private getTenantInfo(): void {
    const callable = httpsCallable<
      { tenantName: string },
      { tenantId: string; emailSignIn: boolean }
    >(this.functions, FunctionName.getTenantInfo, {});
    callable({ tenantName: this.getTenantName() }).then((result) => {
      if (result.data) {
        const tenantId = result.data.tenantId;
        this.auth.tenantId = tenantId;
        console.log(`Tenant set to ${tenantId}`);

        this.firestore.getDocument<Tenant>('').subscribe((tenant) => {
          this._tenant.next(tenant || undefined);
        });

        this.auth.onAuthStateChanged((user) => {
          if (user) {
            this.findUser(user.uid);
          }
        });
      }
    });
  }
}
