import { BehaviorSubject, from, Observable, of, startWith, tap } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { keepUnstableUntilFirst } from '@angular/fire';
import { Auth } from '@angular/fire/auth';
import { traceUntilFirst } from '@angular/fire/performance';
import {
  getBlob,
  getDownloadURL,
  ref,
  Storage,
  StorageReference,
  uploadBytesResumable,
  UploadTask,
} from '@angular/fire/storage';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';

const TRANSPARENT_PNG =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private storage: Storage = inject(Storage);
  private auth: Auth = inject(Auth);
  private http: HttpClient = inject(HttpClient);

  private _uploads$ = new BehaviorSubject<
    Array<{ fileId: string; percentage: number }>
  >([]);
  readonly uploads$ = this._uploads$.asObservable();

  constructor() {}

  uploadFile(
    filePath: string,
    file: Blob | Uint8Array | ArrayBuffer,
    fileId: string,
  ): UploadTask {
    this._uploads$.next([...this._uploads$.value, { fileId, percentage: 0 }]);
    const storageRef = this.getStorageRef(filePath);
    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on(
      'state_changed',
      (snapshot) => {
        this._uploads$.next(
          this._uploads$.value.map((upload) =>
            upload.fileId === fileId
              ? {
                  fileId,
                  percentage:
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
                }
              : upload,
          ),
        );
      },
      () => {
        this._uploads$.next(
          this._uploads$.value.filter((upload) => upload.fileId !== fileId),
        );
      },
      () => {
        this._uploads$.next(
          this._uploads$.value.filter((upload) => upload.fileId !== fileId),
        );
      },
    );
    return uploadTask;
  }

  getDownloadUrl(filePath: string, fullPath = false): Observable<string> {
    try {
      const url = sessionStorage.getItem(`file:${filePath}`);
      if (url) {
        return of(url);
      }
    } catch (exception) {
      // do nothing
    }
    const storageRef = fullPath
      ? ref(this.storage, filePath)
      : this.getStorageRef(filePath);
    return from(getDownloadURL(storageRef)).pipe(
      tap((url) => {
        sessionStorage.setItem(`file:${filePath}`, url);
      }),
      keepUnstableUntilFirst,
      traceUntilFirst('storage'),
      startWith(TRANSPARENT_PNG),
    );
  }

  downloadMultiple(filePaths: string[], fullPath = false): Observable<boolean> {
    const zip = new JSZip();

    const downloads = filePaths.map((filePath) =>
      getBlob(
        fullPath ? ref(this.storage, filePath) : this.getStorageRef(filePath),
      ).then((blob) => ({
        fileName: filePath.split('/').pop(),
        data: blob,
      })),
    );

    return from(
      Promise.all(downloads).then((filesWithData) => {
        filesWithData.forEach((file) => {
          zip.file(file.fileName, file.data, { binary: true });
        });

        return zip.generateAsync({ type: 'blob' }).then((content) => {
          if (content) {
            saveAs(content);
            return true;
          } else {
            return false;
          }
        });
      }),
    );
  }

  private getStorageRef(filePath: string): StorageReference {
    return ref(this.storage, `${this.auth.tenantId}/${filePath}`);
  }
}
