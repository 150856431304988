export enum FunctionName {
  fPrintSelectApiKeys = 'http-fprint-apiKeys',
  fPrintSelectUsage = 'http-fprint-usage',
  getTenantInfo = 'http-tenant-getInfo',
  getModelRegistrationInfo = 'http-model-registrationInfo',
  getModelConsentFormInfo = 'http-model-consentInfo',
  confirmModelRegistration = 'http-model-registrationConfirm',
  saveModelRegistration = 'http-model-registrationSave',
  modelEmailInvite = 'http-model-emailInvite',
  resendEmail = 'http-model-resendEmail',
  revokeConsent = 'http-model-revokeConsent',
  generatePDF = 'http-model-generatePdf',
  addUser = 'http-user-add',
  deleteUser = 'http-user-delete',
  sendImagesToBynder = 'http-export-bynder',
  sendImagesToLytho = 'http-export-lytho',
  callMonitoringResults = 'http-fprint-assetMonitoringResults',
  extendProject = 'http-project-extend',
  elasticSearch = 'http-elastic-search',
  faceAiTagAssets = 'http-faceAi-tagAssets',
  getSuggestions = 'http-faceAi-getSuggestions',
  deleteItems = 'http-delete-deleteItems',
}
